<template>
  <div>
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div> <h1> <strong>FICHE STATISTIQUE</strong> </h1>  </div>
        </b-col>
        <b-col md="12">
          <div>
            <small>(Agence  de transport)</small>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Spacer -->
    <hr class="invoice-spacing">

    <b-card-body class="invoice-padding pt-0">
      <div class="d-flex justify-content-center text-center mb-1">
        <b-row>
          <h6 class="mb-25">
            {{ validOutput ? validOutput.mois : '' }}  {{ validOutput ? validOutput.annee : '' }}
          </h6>
        </b-row>
      </div>
    </b-card-body>

    <b-card-body class=" nopadding">
      <div class="pl-1 pr-1">
        <b-row>
          <b-col md="2" />
          <b-col
            md="4"
            class=""
          >
            Département: <strong> {{ validOutput.departement }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-right"
          >
            Commune: <strong> {{ validOutput.departement }} </strong>
          </b-col>
          <b-col md="2" />
          <b-col md="2" />
          <b-col md="4">
            Arrondissement: <strong> {{ validOutput.arrondissement }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-right"
          >
            Quartier/Village: <strong> {{ validOutput.quartier }} </strong>
          </b-col>
          <b-col md="2" />
        </b-row>
      </div>
    </b-card-body>

    <!-- renseignement generaux -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> I- RENSEIGNEMENTS GÉNÉRAUX </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            Nom de l'établissement: <strong> {{ validOutput.nomEtablissement }} </strong>
          </b-col>
          <b-col md="6" class="mb-1 text-right">
            Type de transport: <strong> {{ get_sous_type }}</strong>
          </b-col>
          <!-- <b-col
            md="6"
            class="mb-1 text-right"
          >
            Numéro IFU: <strong> {{ validOutput.numIFU }} </strong>
          </b-col> -->
          <b-col
            md="6"
            class="mb-1"
          >
            Nom du promoteur: <strong> {{ validOutput.nomPromoteur }} </strong>
          </b-col>
          <b-col
            md="6"
            class="mb-1 text-right"
          >
            Nationalité du promoteur: <strong> {{ validOutput.nationalitePromoteur }} </strong>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            Adresse complète de l'établissement: <strong> {{ validOutput.adresseComplete }} </strong>
          </b-col>
          <b-col
            md="6"
            class="mb-1 text-right"
          >
            Type de transport: <strong> {{ validOutput.typeEtablissement }} </strong>
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            Téléphone:
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <strong> {{ validOutput.telephone1 }} </strong>
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <strong> {{ validOutput.telephone2 }} </strong>
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <strong> {{ validOutput.telephone3 }} </strong>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            Détenez-vous un agrément du Ministère en charge du transport ?
            <strong>
              {{ validOutput.agrementMinistere }} {{ validOutput.agrementMinistere == "oui" ? ', '+validOutput.referenceAgrement : ' ' }}
            </strong>
          </b-col>
          <!-- <b-col
            md="6"
            class="mb-1 text-right"
          >
            Réservé à l'administration: <strong> {{ validOutput.reserveAdministration }} </strong>
          </b-col> -->
        </b-row>
      </div>
    </b-card-body>

    <!-- Capacité d'accueil -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> II- CAPACITE D'ACCUEIL </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <b-col
            v-if="validOutput.typeEtablissement === 'Par route'"
            md="12"
            class="mb-1"
          >
            Pour le transport par route: Nombre de véhicules <strong> {{ validOutput.capacitesAccueil.nombreVehicule }} </strong>
          </b-col>
          <b-col
            v-if="validOutput.typeEtablissement === 'Par eau'"
            md="12"
            class="mb-1"
          >
            Pour le transport par eau: Nombre de bateaux <strong> {{ validOutput.capacitesAccueil.nombreBateaux }} </strong>
          </b-col>
          <b-col
            v-if="validOutput.typeEtablissement === 'Par avion'"
            md="12"
            class="mb-1"
          >
            Pour le transport par avion: Nombre d'aéronefs <strong> {{ validOutput.capacitesAccueil.nombreAeronefs }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Nombre de places disponibles <strong> {{ validOutput.capacitesAccueil.nombrePlacesDisponibles }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Nombre de voyageurs transportés<strong> {{ validOutput.capacitesAccueil.nombreVoyageursTransporter }} </strong>
          </b-col>
        </b-row>
        <hr>
      </div>
    </b-card-body>

    <!-- emplois -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> III- EMPLOIS </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <!-- employés salariés -->
      <div class="table-responsive text-center">
        <table class="table table-bordered">
          <thead class="bg-primary text-white">
            <tr>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                rowspan="2"
              >
                Nombre d'employés salariés
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Hommes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Femmes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Total
              </th>
            </tr>
            <tr>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Nationaux
              </th>
              <td>{{ validOutput.employesSalaries.hommeTempspleinNationaux }}</td>
              <td>{{ validOutput.employesSalaries.hommeTempspartielNationaux }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspleinNationaux }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspartielNationaux }}</td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinNationaux +
                    validOutput.employesSalaries.femmeTempspleinNationaux
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielNationaux +
                    validOutput.employesSalaries.femmeTempspartielNationaux
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Étrangers
              </th>
              <td>{{ validOutput.employesSalaries.hommeTempspleinEtrangers }}</td>
              <td>{{ validOutput.employesSalaries.hommeTempspartielEtrangers }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspleinEtrangers }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspartielEtrangers }}</td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinEtrangers +
                    validOutput.employesSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielEtrangers +
                    validOutput.employesSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Total
              </th>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinNationaux +
                    validOutput.employesSalaries.hommeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielNationaux +
                    validOutput.employesSalaries.hommeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.femmeTempspleinNationaux +
                    validOutput.employesSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.femmeTempspartielNationaux +
                    validOutput.employesSalaries.femmeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinNationaux +
                    validOutput.employesSalaries.femmeTempspleinNationaux +
                    validOutput.employesSalaries.hommeTempspleinEtrangers +
                    validOutput.employesSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielNationaux +
                    validOutput.employesSalaries.femmeTempspartielNationaux +
                    validOutput.employesSalaries.hommeTempspartielEtrangers +
                    validOutput.employesSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- nouveeaux employés du mois  -->
      <div class="table-responsive text-center mt-3">
        <table class="table table-bordered">
          <thead class="text-white tableHeadFontSize">
            <tr>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Salarié recrutés au cours du mois
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
              >
                Hommes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
              >
                Femmes
              </th>
              <th
                scope="col"
                class="bg-primary"
                tableHeadFontSize
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Nombre
              </th>
              <td>{{ validOutput.nouveauEmplois.hommes }}</td>
              <td>{{ validOutput.nouveauEmplois.femmes }}</td>
              <td>
                {{
                  validOutput.nouveauEmplois.hommes + validOutput.nouveauEmplois.femmes
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- </b-card-body> -->
      <!-- <b-card-body class="invoice-padding"> -->

      <!-- employés non salariés -->
      <div class="table-responsive text-center mt-3">
        <table class="table table-bordered">
          <thead class="bg-primary text-white tableHeadFontSize">
            <tr>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                rowspan="2"
              >
                Nombre d'employés non salariés
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Hommes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Femmes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Total
              </th>
            </tr>
            <tr>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Nationaux
              </th>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspleinNationaux }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspartielNationaux }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspleinNationaux }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspartielNationaux }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinNationaux +
                    validOutput.employesNonSalaries.femmeTempspleinNationaux
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielNationaux +
                    validOutput.employesNonSalaries.femmeTempspartielNationaux
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Étrangers
              </th>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspleinEtrangers }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspartielEtrangers }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspleinEtrangers }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspartielEtrangers }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinEtrangers +
                    validOutput.employesNonSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielEtrangers +
                    validOutput.employesNonSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Total
              </th>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinNationaux +
                    validOutput.employesNonSalaries.hommeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielNationaux +
                    validOutput.employesNonSalaries.hommeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.femmeTempspleinNationaux +
                    validOutput.employesNonSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.femmeTempspartielNationaux +
                    validOutput.employesNonSalaries.femmeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinNationaux +
                    validOutput.employesNonSalaries.femmeTempspleinNationaux +
                    validOutput.employesNonSalaries.hommeTempspleinEtrangers +
                    validOutput.employesNonSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielNationaux +
                    validOutput.employesNonSalaries.femmeTempspartielNationaux +
                    validOutput.employesNonSalaries.hommeTempspartielEtrangers +
                    validOutput.employesNonSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>

    <!-- renseignment exploitation du mois -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> IV- RENSEIGNEMENTS SUR L'EXPLOITATION DU MOIS</strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            CHIFFRE D'AFFAIRE GLOBAL: <strong> {{ validOutput.chiffreAffaireGlobal }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            CHARGES SALARIALES: <strong> {{ validOutput.chargesSalariales }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            AUTRES DÉPENSES: <strong> {{ validOutput.autresDepense }} </strong>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Logo from '@core/layouts/components/Logo.vue'
import utilsService from '@/services/utils/utils.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    vSelect,
    Logo,

  },
  directives: {
    Ripple,
  },
  props: {
    validOutput: Object,
  },
  computed: {
    get_sous_type(){
      const { currentUserId, etablissementDetails } = utilsService.currentUserUtils()
      const { sousTypeEtablissement } = etablissementDetails
      return sousTypeEtablissement
    }
  },
  data() {
    return {
    }
  },

  mounted() {
    // this.validOutput.agrementMinistere = 'non'
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.title-background{
  background-color: #ababab;
}

.tableHeadFontSize{
  font-size: 10px;
}
</style>
